.player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

#player {
  display: none !important;
}

.control-player {
  display: flex;
  align-items: center;
}

.button-vol {
  width: 100%;

  input[type="range"] {
    background: transparent;
    width: 100%;
    height: 28px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: 0;
    padding: 0 8px;

    @mixin track {
      background: #fff; /*trackColor*/
      height: 4px; /*trackHeight*/
      border-radius: 4px; /*trackHeight*/
      transition: 0.3s;
    }

    @mixin thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #fff;
      width: 15px;
      height: 15px;
      margin-top: -6px;
      cursor: pointer;
      transition: 0.3s;
      border: none;
      border-radius: 0;
      box-shadow: 5px 5px 0 #020406;
    }

    &::-webkit-slider-runnable-track {
      @include track;
    }
    &::-webkit-slider-thumb {
      @include thumb;
    }

    &::-moz-range-track {
      @include track;
    }
    &::-moz-range-thumb {
      @include thumb;
    }

    &::-ms-track {
      @include track;
    }
    &::-ms-thumb {
      @include thumb;
    }
  }
}

.buttons-wrapper {
  margin: 1rem 0;
  display: flex;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    .dot {
      background: #fff;
    }
  }
  &.left:hover {
    transform: translateX(-5px);
  }
  &.right:hover {
    transform: translateX(5px);
  }
}

.button-container {
  padding: 8px;
}

.dot {
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 5px;
  height: 5px;
  background: #d9d9d9;
  box-shadow: 1px 1px 0px rgba(30, 50, 40, 0.5);
}

.button {
  position: relative;
  width: 15px;
  height: 25px;
}
.button-left {
  .dot-1 {
    top: 0;
    left: 10px;
  }
  .dot-2 {
    top: 5px;
    left: 5px;
  }
  .dot-3 {
    top: 10px;
    left: 0px;
  }
  .dot-4 {
    top: 15px;
    left: 5px;
  }
  .dot-5 {
    top: 20px;
    left: 10px;
  }
}

.button-right {
  .dot-1 {
    top: 0;
    left: 0;
  }
  .dot-2 {
    top: 5px;
    left: 5px;
  }
  .dot-3 {
    top: 10px;
    left: 10px;
  }
  .dot-4 {
    top: 15px;
    left: 5px;
  }
  .dot-5 {
    top: 20px;
    left: 0px;
  }
}
