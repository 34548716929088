.timer-wrapper {
  // z-index: 1;
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -90%);
}

#timer {
  letter-spacing: 5px;
  font-size: max(7vw, 60px);
  color: #f5f5f5;
  text-shadow: 5px 5px 0px #020406;
}
