.genre-playlist-container {
  padding-left: 20px;
  padding-top: 20px;
  position: absolute;
}

.genre-playlist-title {
  width: max(30vw, 300px);
  letter-spacing: 5px;
  color: #f5f5f5;
  text-shadow: 4px 4px 0px #020406;
}

.selected-list-wrapper {
  margin-top: 10px;
  z-index: 1000;
  top: 0;
  left: 0;
  align-items: center;
  min-width: 5em;
  max-width: 12em;
  display: grid;
  grid-template-areas: "select";
  padding: auto;
  box-shadow: 5px 5px 0 #020406;
  background: white;
}

.selected-list-wrapper::after {
  width: 1em;
  background: #020406;
  content: "";
  -webkit-clip-path: polygon(
    0% 20%,
    20% 20%,
    20% 40%,
    40% 40%,
    40% 60%,
    60% 60%,
    60% 40%,
    80% 40%,
    80% 20%,
    100% 20%,
    100% 40%,
    80% 40%,
    80% 60%,
    60% 60%,
    60% 80%,
    40% 80%,
    40% 60%,
    20% 60%,
    20% 40%,
    0% 40%
  );
  clip-path: polygon(
    0% 20%,
    20% 20%,
    20% 40%,
    40% 40%,
    40% 60%,
    60% 60%,
    60% 40%,
    80% 40%,
    80% 20%,
    100% 20%,
    100% 40%,
    80% 40%,
    80% 60%,
    60% 60%,
    60% 80%,
    40% 80%,
    40% 60%,
    20% 60%,
    20% 40%,
    0% 40%
  );
  height: 1em;
  margin: auto;
  margin-right: 10px;
}

.selected-list-wrapper select,
.selected-list-wrapper::after {
  grid-area: select;
}

.genre-list {
  border: none;
  padding: 8px;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
