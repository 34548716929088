@font-face {
  font-family: "Hardpixel";
  src: url("https://db.onlinewebfonts.com/t/46ed1daa8d58529ffb76987f884f2b03.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/46ed1daa8d58529ffb76987f884f2b03.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://db.onlinewebfonts.com/t/46ed1daa8d58529ffb76987f884f2b03.woff2")
      format("woff2"),
    /* chrome firefox */
      url("https://db.onlinewebfonts.com/t/46ed1daa8d58529ffb76987f884f2b03.woff")
      format("woff"),
    /* chrome firefox */
      url("https://db.onlinewebfonts.com/t/46ed1daa8d58529ffb76987f884f2b03.ttf")
      format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url("https://db.onlinewebfonts.com/t/46ed1daa8d58529ffb76987f884f2b03.svg#Hardpixel")
      format("svg"); /* iOS 4.1- */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  font-family: "Hardpixel", serif;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

/* Hidden scroll */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.281);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.app {
  min-height: 100vh;
  display: flex;
}

.full-screen-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(50%);
}

#content {
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
